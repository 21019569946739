<template>
  <v-content class="pageBody">
    <!-- <h2 class="pageTitle pt-5 mb-8">問診票</h2> -->
    <p v-if="showQuestions">以下のアンケートに解答してください</p>
    <!-- <p v-if="showAnswer">
      このQRコードを受付で見せて下さい
    </p> -->
    <real-prediction
      @make-qr-code="makeQRcode"
      :data="predictionData"
      :answer="true"
      class="my-6 elevation-3"
      v-if="showQuestions"
      :edit="editAnswer"
    ></real-prediction>
    <v-card v-if="showAnswer" class="text-center pink lighten-5 pt-12 pb-4">
      <p>このQRコードを受付で見せて下さい</p>
      <p class="red mx-4 white--text bold">
        スクリーンショットをとってください
      </p>
      <vue-qrcode
        :value="predictionAnswer"
        :options="option"
        tag="img"
      ></vue-qrcode>
      <v-row>
        <v-btn class="mt-4 ml-8" color="primary" @click="returnQuestions"
          >アンケートに戻る</v-btn
        >
      </v-row>
    </v-card>
  </v-content>
</template>

<script>
import firebase from "firebase";
import realPrediction from "@/components/realPrediction.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import FrontCommon from "@/assets/js/frontCommon";

export default {
  components: {
    realPrediction,
    VueQrcode,
  },
  data: () => ({
    examId: "",
    predictionData: {},
    predictionAnswer: "",
    showQuestions: false,
    showAnswer: false,
    editAnswer: false,
    option: {
      errorCorrectionLevel: "M",
      maskPattern: 0,
      margin: 10,
      scale: 2,
      width: 300,
      color: {
        dark: "#000000FF",
        light: "#FFFFFFFF",
      },
    },
    defaultQuestions: [],
  }),
  created() {
    this.defaultQuestions = FrontCommon.defaultQuestions();
    this.examId = this.$router.currentRoute.query.id;
    if (this.examId) {
      firebase
        .firestore()
        .collection("exam_data")
        .doc(this.examId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            [this.predictionData] = doc.data().predictionData;
            this.showQuestions = true;
          }
        });
    }
  },
  methods: {
    makeQRcode(answerData) {
      let answer = this.examId;
      answer += "?T123456789";
      this.defaultQuestions.forEach((obj) => {
        answer += "?0";
        answer += encodeURIComponent(answerData[obj.value]);
      });
      answerData.questions.forEach((question) => {
        let add = question.radioAnswer === "n" ? "0" : "1";
        // answer += add;
        if (question.memo) {
          add += question.memoAnswer;
        }
        add = encodeURIComponent(add);
        answer += "?";
        answer += add;
      });
      answer += "?";
      answer += answerData.questions.length;
      this.predictionAnswer = answer;
      this.showAnswer = true;
      this.showQuestions = false;
    },
    returnQuestions() {
      this.showAnswer = false;
      this.showQuestions = true;
      this.editAnswer = true;
    },
  },
  watch: {},
  filters: {},
};
</script>
<style>
.pageTitle {
  border-bottom: solid 3px rgba(0, 0, 0, 0.87);
}
.pageBody {
  margin-top: 60px;
}
</style>
